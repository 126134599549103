@import 'Styles/includes';

.Video {
    $root: &;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
}
