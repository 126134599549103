@import 'Styles/includes';

.LandingArkitekturgalan {
    $root: &;

    &__Header {
        position: relative;
    }

    &__Gifs {
        background: white;
    }

    &__Wrap {
        @include wrap;

        position: relative;
    }

    &__Gif {
        text-align: center;

        img {
            width: auto;
            max-width: 100%;
            height: auto;
        }

        &--S {
            display: block;

            @include media(M) {
                display: none;
            }
        }

        &--M {
            display: none;

            @include media(M) {
                display: block;
            }

            @include media(L) {
                display: none;
            }
        }

        &--L {
            display: none;

            @include media(L) {
                display: block;
            }

            @include media(XL) {
                display: none;
            }
        }

        &--XL {
            display: none;

            @include media(XL) {
                display: block;
            }
        }
    }

    &__Content {
        margin-top: -15px;

        @include media(XL) {
            margin-top: -50px;
        }
    }
}
