@use 'sass:math';

@import 'Styles/includes';

.VideoChat {
    $root: &;

    &__Video {
        position: relative;

        &::before {
            content: '';
            display: block;
            height: 0;
            width: 100%;
            padding-bottom: percentage(math.div(9, 16));
        }
    }

    &__Button {
        @include button(primary);

        align-self: flex-end;
    }

    &__Chat {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 16px;
    }

    &__Popup {
        position: absolute;
        top: -16px;
        width: 100%;
        padding: 56px 16px 16px;
        background: white;
        box-shadow: 0 0 32px 0 rgba(black, .12);
        z-index: 1;

        iframe {
            width: 100%;
        }
    }

    &__Close {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: transparent;
        transition: background $transition;
        outline: none;
        z-index: $z-GalleryModal + 2;
        cursor: pointer;

        &:hover,
        &:focus {
            background: rgba(white, .3);
        }

        &::before,
        &::after {
            position: absolute;
            left: 11px;
            top: 2px;
            content: ' ';
            height: 20px;
            width: 2px;
            background-color: $arkitekturgalan-link;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }
}
